import { h } from 'phy-emotion';
import { Link } from 'gatsby';
import Layout from '../components/layout';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = () => {
  return h(
    Layout,
    { siteTitle: '404: Page not found' },
    h('main', { css: pageStyles }, [
      h('title', 'Not found'),
      h('h1', { css: headingStyles }, 'Page not found'),
      h('p', { css: paragraphStyles }, [
        'Sorry ',
        h('span', { role: 'img', 'aria-label': 'Pensive emoji' }, '😔'),
        " we couldn't find what you were looking for.",
        h(Link, { to: '/' }, 'Go home'),
        '.',
      ]),
    ])
  );
};

export default NotFoundPage;
